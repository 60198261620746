import React, { useState } from "react"
import { Container, Row, Col, Dropdown } from "react-bootstrap"
import Pagination from "react-bootstrap/Pagination"
import { LoadMore } from "../Button"
import "./style.scss"
import ReactPaginate from "react-paginate"
import { navigate } from "gatsby"

const getTotalPages = (postsPerPage, totalCount) =>
  Math.ceil(totalCount / postsPerPage)

export default ({
  sorting = true,
  sortMode = "newest",
  onClickSort,
  loadMore = false,
  currentPage,
  numPages,
  totalCount,
  postsPerPage = 8,
  urlPrefix,
}) => {
  const totalPages = numPages
    ? numPages
    : getTotalPages(postsPerPage, totalCount)
  const [mode, setMode] = useState(sortMode)

  const PaginateList = ({ totalPages, currentPage }) => (
    <ReactPaginate
      initialPage={currentPage - 1}
      pageCount={totalPages}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      onPageChange={({ selected }) =>
        selected === 0
          ? navigate(urlPrefix, { state: { sort: mode } })
          : navigate(`${urlPrefix}/${selected + 1}`, { state: { sort: mode } })
      }
      disableInitialCallback={true}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      activeClassName={"active"}
    />
  )

  return (
    <Container fluid>
      <Container className={"pagination-wrap no-padding"}>
        <Row noGutters className={"no-padding pt-sm-3"}>
          <Col className={"mt-4 mx-auto"} md={5}>
            {loadMore !== false && <LoadMore onClick={() => loadMore()} />}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
