import React, { useState, useEffect } from "react"
import { Container, Col, Row, Form } from "react-bootstrap"
import Layout from "../../components/Layout"
import Subheading from "../../components/Subheading"
import LoadMore from "../../components/Pagination/LoadMore"
import ToolboxCards from "../../components/Cards/ToolboxList"
import SEO from "../../components/seo"
import "./../../styles/dark-mode.scss"
import { graphql } from "gatsby"
import { isEmpty, remove, indexOf } from "lodash"

export default ({
  data: {
    pageInfo: { title, description },
    languages: { distinct: AvailableLanguages },
    categories: { distinct: AvailableCategories },
    toolboxResources: { nodes: toolboxResources },
  },
  location: { state },
}) => {
  let getData = (selectedLanguages, selectedCategories) => {
    let result = toolboxResources.filter(toolboxResource => {
      let toolboxResourceCategories = toolboxResource.relationships.field_resource_category.map(
        category => category.name
      )

      const categoriesIntersection = selectedCategories.filter(value =>
        toolboxResourceCategories.includes(value)
      )

      if (
        categoriesIntersection.length ||
        selectedLanguages.includes(toolboxResource.langcode)
      ) {
        return true
      } else {
        return false
      }
    })
    return result
  }

  const [currentList, updateCurrentList] = useState([toolboxResources])
  const [selectedCategories, updateSelectedCategories] = useState([])
  const [selectedLanguages, updateSelectedLanguages] = useState([])
  const [mode, setMode] = useState(state ? state.sort : "newest")
  const [maxLimit, updateMaxLimit] = useState(9)

  useEffect(() => {
    if (selectedLanguages.length === 0 && selectedCategories.length === 0) {
      setMode("newest")
      updateCurrentList(() => [...toolboxResources.slice(0, maxLimit)])
    } else {
      setMode("filter")
      updateCurrentList(getData(selectedLanguages, selectedCategories))
    }
  }, [selectedLanguages, selectedCategories])

  const getLanguageLabel = langcode => {
    const dictionary = {
      de: "German",
      en: "English",
      es: "Spanish",
      fr: "French",
      "fr-ca": "Canadian-French",
      ru: "Russian",
    }
    return dictionary[langcode] || ""
  }

  return (
    <Layout fluid={true} pageInfo={{ pageName: "First Responders Toolbox" }}>
      <SEO
        title="First Responders Toolbox"
        keywords={[`toolbox`, `first responders`]}
      />
      <Subheading lg={12} md={12} title={title}>
        {description}
      </Subheading>
      <Container className="main-section p0">
        <Row>
          <Col lg={3} className={"filter"}>
            <h2>Filter</h2>
            <Container className={"filter-list mb-4"}>
              <h3 className={"pb-2"}>Category</h3>
              <ul>
                {AvailableCategories.map(item => (
                  <li key={item}>
                    <Form.Check
                      type="checkbox"
                      id={item}
                      label={item}
                      checked={-1 !== indexOf(selectedCategories, item)}
                      onChange={e => {
                        let values = [...selectedCategories]
                        if (e.target.checked) {
                          values.push(e.target.id)
                        } else {
                          remove(values, item => item === e.target.id)
                        }
                        updateSelectedCategories(values)
                      }}
                    />
                  </li>
                ))}
              </ul>
              <hr style={{ backgroundColor: "#3d3f43" }} />
              <h3 className={"pb-2"}>Language</h3>
              <ul>
                {AvailableLanguages.map(item => (
                  <li key={item}>
                    <Form.Check
                      type="checkbox"
                      id={item}
                      label={getLanguageLabel(item)}
                      checked={-1 !== indexOf(selectedLanguages, item)}
                      onChange={e => {
                        let values = [...selectedLanguages]
                        if (e.target.checked) {
                          values.push(e.target.id)
                        } else {
                          remove(values, item => item === e.target.id)
                        }
                        updateSelectedLanguages(values)
                      }}
                    />
                  </li>
                ))}
              </ul>
            </Container>
            <a
              href="https://us541.agstorefront.com/uStore/13/Home"
              className="block"
              target="_blank"
            >
              <img
                className={"w-100 mb-4"}
                alt={""}
                src={require("../../assets/images/noprinter-noproblem.png")}
              />
            </a>
          </Col>
          <Col lg={9}>
            <Container>
              {isEmpty(currentList) && (
                <Subheading lg={12} md={12} title={"No Results"}>
                  {"Please try an alternative selection to find results."}
                </Subheading>
              )}
              <ToolboxCards
                items={
                  mode === "filter"
                    ? currentList
                    : toolboxResources.slice(0, maxLimit)
                }
              />
              {!isEmpty(currentList) && "filter" != mode && (
                <LoadMore
                  urlPrefix={`/first-responders/toolbox`}
                  loadMore={() => {
                    updateMaxLimit(maxLimit + 9)
                  }}
                />
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const ToolboxQuery = graphql`
  query ToolBoxQuery {
    pageInfo: pagesYaml(slug: { eq: "fp-toolbox" }) {
      title
      description
    }

    languages: allNodeToolboxResource {
      distinct(field: langcode)
    }

    categories: allNodeToolboxResource {
      distinct(field: relationships___field_resource_category___name)
    }

    toolboxResources: allNodeToolboxResource(
      sort: { order: DESC, fields: [created] }
    ) {
      nodes {
        id
        title
        caption: field_caption
        langcode
        published: created(formatString: "MMMM D, YYYY")
        link: field_link {
          uri
        }
        relationships {
          field_resource_category {
            name
          }
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
